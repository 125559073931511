module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","map","css","woff","woff2","otf","ttf"]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en-US"],"defaultLanguage":"en-US","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"e0100b53bea53562ccc73d0c2a29a191","enableOnDevMode":true},
    },{
      plugin: require('../../gatsby-theme-ecom/gatsby-browser.ts'),
      options: {"plugins":[],"siteMetadata":{"title":"ZipJob","siteUrl":"https://stg.zipjob.com","brandName":"ZipJob","trailingSlash":true,"trustpilot":{"businessUnitId":"577fe4180000ff0005921771","reviewTags":["shown-on-website","quality","result","working-with-your-writer","delivery-process"]},"siteID":238,"locale":"en-US","i18nextLocale":"en-US","defaultResumeProduct":"resume","googleTagManagerCode":"GTM-PWK4NTH","intercomWidgetColor":"#038dff","intercomAppId":"ysdthg67","domainName":"zipjob.com"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ZipJob","short_name":"ZipJob","description":"Professional Resume Writing & Editing Services by Professional Resume Writers","start_url":"/","background_color":"#ffffff","theme_color":"#0a8080","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:n4,n7&display=swap"],"subsets":["latin","latin-ext"],"formats":["woff2"]},"typekit":[{"id":"nyr0cch"},{"id":"070ec7","families":["century-old-style-std:n7"],"loading":"swap"},{"id":"660cc5","families":["century-old-style-std:i4"],"loading":"swap"},{"id":"942e0d","families":["century-old-style-std:n4"],"loading":"swap"}],"custom":{"families":["century-old-style-std"],"urls":["./src/styles/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
